<template>
  <div class="file-material">
    <div class="top-btns">
      <el-button type="primary" :loading="refreshStatus.image" icon="el-icon-refresh" @click="refreshMaterial(1)" size="mini" v-power="`40-40-20-10`">刷新图片</el-button>
      <el-button type="success" :loading="refreshStatus.video" icon="el-icon-refresh" @click="refreshMaterial(2)" size="mini" v-power="`40-40-20-20`">刷新视频</el-button>
      <el-button type="warning" :loading="refreshStatus.voice" icon="el-icon-refresh" @click="refreshMaterial(3)" size="mini" v-power="`40-40-20-30`">刷新语音</el-button>
    </div>
    
    <!-- 查询表单 -->
    <div class="search-form">
      <el-form inline size="mini">
        <el-form-item label="类型">
            <code-domain-select v-model="searchForm.mediaType" code-domain="domain.WxMaterial.PlainType" />
        </el-form-item>
        <el-form-item label="编码">
          <el-input v-model="searchForm.mediaCodeLike" clearable />
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="searchForm.mediaNameLike" clearable />
        </el-form-item>
        <el-form-item label="更新时间">
          <el-date-picker
            v-model="updateTimeRange"
            type="datetimerange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 数据表格 -->
    <common-table
      ref="fileMaterial"
      :apiKey="tableConfig.apiKey"
      :autoLoad="tableConfig.autoLoad"
      :search-params="searchForm"
      :columns="tableConfig.columns"
      :options="tableConfig.options"
      :refresh-on-app-change="true"
    />


    <el-dialog 
        :title="editDialogInfo.title"
        :visible.sync="editDialogInfo.show"
        :close-on-click-modal="false"
        center
        destroy-on-close>
        <el-form size="mini" label-width="50px" label-suffix=":">
            <el-form-item label="编码" >
                <el-input v-model="editMdeiaCodeForm.mediaCode" clearable />
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" size="mini" @click="saveMediaCode">保存</el-button>
        </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "FileMaterial",
  data() {
    return {
      searchForm: {},
      refreshStatus:{
        image: false,
        video: false,
        voice: false
      },
      updateTimeRange: [],
      editDialogInfo: {
        title: '修改编码',
        show: false
      },
      editMdeiaCodeForm: {
        id: null,
        mediaCode: null
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              let today = new Date();
              let year = today.getFullYear();
              let month = today.getMonth() + 1;
              let day = today.getDate();
              let start = new Date(year + "-" + month + "-" + day + " 00:00:00");
              let end = new Date(year + "-" + month + "-" + day + " 23:59:59");
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tableConfig: {
        apiKey: "fw-wx-material-listPlainMaterial",
        autoLoad: true,
        columns: [
          {
            label: "类型",
            prop: "_caption.mediaType",
            width: '50px'
          },
          {
            label: "编码",
            prop: "mediaCode",
            formatter: (row, column, cellValue, index) => {
                if (cellValue && cellValue.length > 50) {
                    return (
                        <el-tooltip content={cellValue} placement={index == 0 ? 'bottom' : 'top'}>
                            <span>{cellValue.substring(0, 47) + '...'}</span>
                        </el-tooltip>
                    )
                }
                return cellValue;
            }
          },
          {
            label: "名称",
            prop: "mediaName",
          },
          {
            label: '图片',
            prop: 'url',
            width: '60px',
            type: 'image'
          },
          {
            label: "更新时间",
            prop: "updateTime",
          },
        ],
        options: [{
            label: '复制链接',
            powerId: '40-40-20-50',
            handler: async (row) => {
                try {
                  await this.$copyText(row.url);
                  this.$message.success('复制成功');
                } catch(e) {
                  this.$message.error('复制失败');
                  console.log(e);
                }
            }
        }, {
            label: '修改编码',
            powerId: '40-40-20-40',
            handler: (row) => {
                this.editMediaCode(row);
            }
        }],
      },
    };
  },
  methods: {
    doSearch() {
      this.$refs.fileMaterial.load(1);
    },
    async refreshMaterial(typeId) {
        this.triggerRefreshStatus(typeId, true);
        try {
          await this.$http.doApi('fw-wx-material-refresh', {
              typeId: typeId
          });
          this.$message.success('刷新成功');
        } finally {
          this.triggerRefreshStatus(typeId, false);
        }
    },
    triggerRefreshStatus(typeId, val) {
        switch(typeId) {
            case 1:
                this.refreshStatus.image = val;
                return;
            case 2:
                this.refreshStatus.video = val;
                return;
            case 3:
                this.refreshStatus.voice = val;
                return;
        }
    },
    editMediaCode(row) {
        this.editMdeiaCodeForm.id = row.id;
        this.editMdeiaCodeForm.mediaCode = row.mediaCode;
        this.editDialogInfo.show = true;
    },
    async saveMediaCode() {
        await this.$http.doApi('fw-wx-material-saveMediaCode', this.editMdeiaCodeForm);
        this.$message.success('保存成功');
        this.editDialogInfo.show = false;
        this.$refs.fileMaterial.refreshCurrent();
    }
  },
  watch: {
    updateTimeRange(newVal) {
        if (newVal && newVal.length > 0) {
            this.searchForm.updateTimeGte = newVal[0];
            this.searchForm.updateTimeLte = newVal[1];
        } else {
            delete this.searchForm.updateTimeGte;
            delete this.searchForm.updateTimeLte
        }
        
    }
  }
};
</script>

<style lang="less">
.file-material {
  padding: 10px;

  .top-btns {
    margin-bottom: 1rem;
  }

  .search-form {
    padding: 8px;
  }
}
</style>